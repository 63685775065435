import { getTokenFromFE } from '@/services/token';
import { NextRequest } from 'next/server';
import wretch from 'wretch';

export const beApi = wretch(`${process.env.BACKEND_URL}/api`)
  .errorType('json')
  .resolve((r) => r.json());

export const rawApi = wretch()
  .errorType('json')
  .resolve((r) => r.json());

export const feApi = wretch(process.env.NEXT_PUBLIC_BASE_URL)
  .errorType('json')
  .resolve((r) => r.json());

export async function getRequestToken(req: NextRequest): Promise<string> {
  const bearerToken = req.headers.get('authorization');

  const token = bearerToken?.split(' ')[1];

  if (!token || token === 'null') {
    const ntoken = await getTokenFromFE();

    return `Bearer ${ntoken}`;

    // throw new Exception({
    //   name: 'GetRequestTokenException',
    //   error: {
    //     message: 'Unauthorized',
    //   },
    //   context: {
    //     status: 401,
    //     level: 'fatal',
    //   },
    // });
  }

  return bearerToken;
}
