import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/assets/fonts/IBMPlexSansArabic-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../public/assets/fonts/IBMPlexSansArabic-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../public/assets/fonts/IBMPlexSansArabic-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../public/assets/fonts/IBMPlexSansArabic-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"IBMPlex\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/src/components/providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleProvider"] */ "/app/src/contexts/locale.tsx");
