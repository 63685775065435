import { setSession } from '@/services/local-services';
import { getTokenFromFE } from '@/services/token';
import { QueryClient, defaultShouldDehydrateQuery, isServer } from '@tanstack/react-query';

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      mutations: {},
      queries: {
        staleTime: 60 * 1000,
        retry: (failureCount, error) => {
          if (failureCount === 2) return false;

          if (error?.message?.toLowerCase().includes('unauthorized')) {
            getTokenFromFE().then((v) => {
              setSession(v);
            });
          }

          return true;
        },
      },

      dehydrate: {
        // include pending queries in dehydration
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}
