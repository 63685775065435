'use client';
import { Button } from '@/components/ui/button';
import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
import Link from '../Link/link';
import { Routes } from './routes';
import { Lang } from '@/i18n/settings';

type Props = {
  className?: string;
  routes: Routes[];
  lang: Lang;
  onLangChange: () => void;
  isChangingLang: boolean;
  inactiveLang?: string | null;
};

export default function MobileNavbar({
  className,
  routes,
  // lang,
  // inactiveLang,
  // isChangingLang,
  // onLangChange,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  // Close the menu when clicking outside of it
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Attach event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='flex items-center w-full justify-between'>
      <div className='relative '>
        <Button
          variant={'ghost'}
          size={'icon'}
          className='hover:bg-transparent'
          onClick={toggleMenu}
        >
          {isOpen ? (
            <>
              <X />
            </>
          ) : (
            <>
              <svg
                width='36'
                height='20'
                viewBox='0 0 46 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.57153 1.42857H44.4287M1.57153 18.5714H44.4287'
                  stroke='#FCFCFC'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </>
          )}
        </Button>

        <motion.div
          ref={menuRef}
          className={cn(
            'fixed flex flex-col pb-4 top-0 -z-10 left-0 w-full  bg-primary shadow-lg',
            className
          )}
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: isOpen ? '55vh' : 0, opacity: isOpen ? 1 : 0 }}
          transition={{ duration: 0.3 }}
          style={{ overflow: 'hidden' }}
        >
          <ul className={cn('flex flex-col px-4 gap-2 -z-10  justify-center my-auto h-full')}>
            {routes.map((route) => (
              <li key={route.name} className='w-full flex' onClick={toggleMenu}>
                <Link
                  className='hover:border-foreground py-2 w-full  border-b-2 border-transparent transition-all ease-in duration-150'
                  href={route.href}
                >
                  {route.name}
                </Link>
              </li>
            ))}
          </ul>
          {/* <div>
            <Button
              variant={'ghost'}
              isLoading={isChangingLang}
              onClick={onLangChange}
              className='!text-sm !font-normal hover:bg-transparent'
            >
              {inactiveLang}
            </Button>
          </div> */}
        </motion.div>
      </div>
    </div>
  );
}
