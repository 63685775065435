'use client';
import { AuthProvider } from '@/contexts/auth';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { getQueryClient } from './get-query-client';
import { ThemeProvider } from './theme-provider';

export function Providers({ children }: { children: React.ReactNode }) {
  const queryClient = getQueryClient();

  return (
    <ThemeProvider attribute='class' defaultTheme='dark' enableSystem disableTransitionOnChange>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>{children}</AuthProvider>
        <ProgressBar height='4px' color='#BD8B2C' options={{ showSpinner: false }} shallowRouting />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
